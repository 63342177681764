let yearFrom = 2011;
const currentYear = new Date().getFullYear()
let yearTo = currentYear
const years = [];

while (yearTo >= yearFrom) {
  years.push(yearTo);
  yearTo += -1
}

const allClassLevels = [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]
const majorClassLevels = [12, 11, 10]

const allClasses = [
  { title: "All Levels", level: allClassLevels },
  { title: "CCI4*-L", level: 12 },
  { title: "CCI4*-S", level: 11 },
  { title: "EI120", level: 10 },
  { title: "CCI3*-L", level: 9 },
  { title: "CCI3*-S", level: 8 },
  { title: "EI115", level: 7 },
  { title: "CCI2*-L", level: 6 },
  { title: "CCI2*-S", level: 5 },
  { title: "EI110", level: 4 },
  { title: "CCI1*-U & EI105", level: 3 },
  { title: "EI100", level: 2 },
  { title: "EI80 & EI90", level: 1 },
]

const majorClassesFilter = (c) => { return majorClassLevels.includes(c.level) };

const variables = {
  providerId: 12,
  currentYear: currentYear,
  years: years,
  allClassLevels: allClassLevels,
  allClasses: allClasses,
  majorClassLevels: majorClassLevels,
  majorClasses: allClasses.filter(majorClassesFilter),
  pointsLeagueDateFrom: `${currentYear}-01-01`,
  pointsLeagueDateTo: `${currentYear}-12-31`,
};

export default variables;
