import React from "react";
import { Router } from "@reach/router";

import {
  SingleMetric,
  Records,
  AllTime,
  Dashboard,
  OBPLeagues,
  EILeague,
} from "./screens";

const Navigation = () => {
  const khw_url = "https://www.kentucky-horsewear.com/"

  return (
    <>
      <Router>
        <Dashboard path="/" />
        <AllTime path="/alltime" />
        <Records path="/records" />
        <SingleMetric path="/analysis" />
        <OBPLeagues path="/redmills" partner="Redmills" />
        <EILeague
          path="/ei90p"
          title="EI90 (P)"
          leagueClass="EI90 (P)"
          sponsor="Kentucky Horsewear"
          link={khw_url}
          bannerImage={{ img: "Banner_AllBrands.jpg", alt: "Kentucky Horsewear", link: khw_url }}
          adverts={[
            { img: "League 1_600x600.jpg", link: khw_url, alt: "Discover DY'ON - Kentucky Horsewear" },
            { img: "League 1_600x6002.jpg", link: khw_url, alt: "Discover Kentucky Horsewear" },
            { img: "League 1_600x6003.jpg", link: khw_url, alt: "Discover Grooming Deluxe - Kentucky Horsewear" }
          ]}
        />
        <EILeague
          path="/ei100p"
          title="EI100 (P)"
          leagueClass="EI100 (P)"
          sponsor="Kentucky Horsewear"
          link={khw_url}
          bannerImage={{ img: "Banner_AllBrands.jpg", alt: "Kentucky Horsewear", link: khw_url }}
          adverts={[
            { img: "League 2_600x600.jpg", link: khw_url, alt: "Discover Kentucky Horsewear Leg Protection" },
            { img: "League 2_600x6002.jpg", link: khw_url, alt: "Discover DY'ON Bridles - Kentucky Horsewear" },
            { img: "League 2_600x6003.jpg", link: khw_url, alt: "Discover Grooming Deluxe Brushes - Kentucky Horsewear" }
          ]}
        />
        <EILeague
          path="/ei110p"
          title="EI110 (P)"
          leagueClass="EI110 (P)"
          sponsor="Kentucky Horsewear"
          link={khw_url}
          bannerImage={{ img: "Banner_AllBrands.jpg", alt: "Kentucky Horsewear", link: khw_url }}
          adverts={[
            { img: "League 3_600x600.jpg", link: khw_url, alt: "Kentucky Horsewear Eventing Essentials" },
            { img: "League 3_600x6002.jpg", link: khw_url, alt: "Kentucky Horsewear Eventing Essentials" },
            { img: "League 3_600x6003.jpg", link: khw_url, alt: "Kentucky Horsewear Eventing Essentials" }
          ]}
        />
        <EILeague
          path="/ei100j"
          title="EI100 (J)"
          leagueClass="EI100 (J)"
          sponsor="Kentucky Horsewear"
          link={khw_url}
          bannerImage={{ img: "Banner_AllBrands.jpg", alt: "Kentucky Horsewear", link: khw_url }}
          adverts={[
            { img: "League 4_600x600.jpg", link: khw_url, alt: "Discover Kentucky Horsewear" },
            { img: "League 4_600x6002.jpg", link: khw_url, alt: "Discover DY'ON - Kentucky Horsewear" },
            { img: "League 4_600x6003.jpg", link: khw_url, alt: "Discover Grooming Deluxe - Kentucky Horsewear" }
          ]}
        />
        <EILeague
          path="/ei110j"
          title="EI110 (J)"
          leagueClass="EI110 (J)"
          sponsor="Kentucky Horsewear"
          link={khw_url}
          bannerImage={{ img: "Banner_AllBrands.jpg", alt: "Kentucky Horsewear", link: khw_url }}
          adverts={[
            { img: "League 5_600x600.jpg", link: khw_url, alt: "Discover Kentucky Horsewear Eventing Essentials" },
            { img: "League 5_600x6002.jpg", link: khw_url, alt: "Discover Kentucky Horsewear Eventing Essentials" },
            { img: "League 5_600x6003.jpg", link: khw_url, alt: "Discover Kentucky Horsewear Eventing Essentials" }
          ]}
        />
        <EILeague
          path="/ei_young_rider_110"
          title="EI110 Young Rider"
          erLevel={4}
          under={21}
          over={18}
          scalePoints={true}
          rerankScores={true}
          sponsor="Kentucky Horsewear"
          link={khw_url}
          bannerImage={{ img: "Banner_AllBrands.jpg", alt: "Kentucky Horsewear", link: khw_url }}
          adverts={[
            { img: "League 6_600x600.jpg", link: khw_url, alt: "Discover Kentucky Horsewear Saddle Pads" },
            { img: "League 6_600x6002.jpg", link: khw_url, alt: "Discover DY'ON Long Chaps - Kentucky Horsewear" },
            { img: "League 6_600x6003.jpg", link: khw_url, alt: "Discover Grooming Deluxe Tack Boxes - Kentucky Horsewear" }
          ]}
        />
        <EILeague
          path="/ei_young_rider_115"
          title="EI115 Young Rider"
          erLevel={7}
          under={21}
          over={14}
          scalePoints={true}
          rerankScores={true}
          sponsor="Kentucky Horsewear"
          link={khw_url}
          bannerImage={{ img: "Banner_AllBrands.jpg", alt: "Kentucky Horsewear", link: khw_url }}
          adverts={[
            { img: "League 7_600x600.jpg", link: khw_url, alt: "Get Ready For Summer With Kentucky Horsewear" },
            { img: "League 7_600x6002.jpg", link: khw_url, alt: "Get Ready For Summer With Kentucky Horsewear" },
            { img: "League 7_600x6003.jpg", link: khw_url, alt: "Get Ready For Summer With Kentucky Horsewear" }
          ]}
        />
        {/* <EloRankings path="/elo" default />
        <EloSearch path="/elo-search" /> */}
        <EILeague
          path="/ei_amateur_90"
          title="EI90 Amateur"
          leagueClass="EI90"
          erLevel={1}
          amateur={true}
          sponsor="Childéric"
          link="https://www.childericsaddles.co.uk/"
          img="Logo Long BIS.png"
        />
        <EILeague
          path="/ei_amateur_100"
          title="EI100 Amateur"
          erLevel={2}
          amateur={true}
          sponsor="Childéric"
          link="https://www.childericsaddles.co.uk/"
          img="Logo Long BIS.png"
        />
        <EILeague
          path="/ei_amateur_110"
          title="EI110 Amateur"
          erLevel={4}
          amateur={true}
          scalePoints={true}
          rerankScores={true}
          sponsor="Childéric"
          link="https://www.childericsaddles.co.uk/"
          img="Logo Long BIS.png"
        />
        <EILeague
          path="/ei_treo_eile"
          title="Treo Eile"
          horseNameContains="(TB)"
          sponsor="Treo Eile"
          img="treoeile.png"
        />
        <EILeague
          path="/ei_tih"
          title="Decron Horsecare TIH Eventing"
          horseNameContains="(TIH)"
          sponsor="Decron Horsecare"
          link="https://decronhorsecare.co.uk/"
          img="DECRON.png"
        />
      </Router>
    </>
  );
};

export default Navigation;
